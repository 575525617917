<template>
  <div class="classCollect">
    <div class="head">
      <div class="title d-flex align-center"><span></span>课程收藏</div>
      <ul class="tab d-flex">
        <li
          class="tab-item d-flex align-center cursor"
          @click="selectList(0)"
          :class="{ tab_active: tabs == 0 }"
        >
          课程收藏
        </li>
        <li
          class="tab-item d-flex align-center cursor"
          @click="selectList(1)"
          :class="{ tab_active: tabs == 1 }"
        >
          直播收藏
        </li>
      </ul>
      <!-- <ul class="tabs d-flex justify-end">
        <li class="tabs-item cursor tabs_active">全部</li>
        <li class="tabs-item cursor">免费</li>
        <li class="tabs-item cursor">购课</li>
      </ul> -->
    </div>
    <ul class="list d-flex flex-wrap" v-show="tabs == 0">
      <li
        class="item d-flex flex-column justify-between"
        v-for="item in 5"
        :key="item"
      >
        <div class="item-top">
          <div class="item-name">21考研政治时政热点总结（7-9）</div>
          <div class="item-time">有效期至：2021.01.31</div>
          <div class="item-time">课时：26</div>
          <div class="teacher d-flex align-center justify-center">
            授课老师：李嘉欣
          </div>
        </div>
        <div class="item-bottom d-flex align-start justify-between">
          <div class="pic"><span>￥</span>3999</div>
          <div class="pic-buy">已购买</div>
          <div
            class="detail-btn d-flex align-center justify-center cursor"
            :style="btn"
            @click="goLive"
          >
            听课学习
          </div>
        </div>
      </li>
    </ul>
    <ul class="live-list" v-show="tabs == 1">
      <li class="live-item d-flex align-center" v-for="item in 5" :key="item">
        <div class="time d-flex flex-column align-center">
          <div class="time-t">27日 周二</div>
          <div class="time-c">19:30-21:30</div>
          <div class="time-b d-flex align-center justify-center">即将开播</div>
        </div>
        <div class="detail d-flex justify-between">
          <div>
            <div class="detail-img"></div>
            <div class="detail-name d-flex align-center">
              2020下半年教师资格《小学教育教学知识》
              <img src="../../assets/img/tag_buy.png" alt="" />
            </div>
            <div class="detail-bottom d-flex align-start">
              <div class="teacher">授课老师：刘晓燕</div>
              <div class="status">已1199人预约</div>
            </div>
          </div>
          <div class="d-flex flex-column align-center justify-betweend">
            <div class="detail-pic">¥129</div>
            <div
              class="detail-btn d-flex align-center justify-center cursor"
              @click="goLive"
            >
              <img src="../../assets/img/time.png" alt="" />
              听课学习
            </div>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  data() {
    return {
      btn: {
        backgroundImage:
          "url(" + require("../../assets/img/live_btn.png") + ")",
        backgroundRepeat: "no-repeat",
        backgroundSize: "100%"
      },
      tabs: 0
    };
  },
  methods: {
    goLive() {
      location.href = "./html/live.html?a=" + "asdasd";
    },
    selectList(index) {
      this.tabs = index;
    }
  }
};
</script>

<style lang="less" scoped>
.tab_active {
  border-color: #5f2eff;
  background: #5f2eff;
  color: #ffffff !important;
}

.classCollect {
  width: 1000px;
  background: #ffffff;
  box-shadow: 0px 0px 10px 0px rgba(221, 221, 221, 0.36);
  border-radius: 6px;
  padding: 33px 36px 36px 36px;
  box-sizing: border-box;
  .head {
    border-bottom: 1px solid #e7e8ef;
    width: 100%;
    padding: 33px 0 6px 0;
    box-sizing: border-box;
    .title {
      font-size: 24px;
      font-weight: bold;
      color: #444444;
      span {
        width: 4px;
        height: 24px;
        background: #5f2eff;
        border-radius: 2px;
        margin-right: 12px;
        display: inline-block;
      }
    }
    .tab {
      margin-top: 24px;
      padding-bottom: 18px;
      box-sizing: border-box;
      .tab-item {
        height: 26px;
        border: 1px solid #dddddd;
        border-radius: 13px;
        padding: 0px 21px;
        font-size: 16px;
        color: #999999;
        margin-right: 18px;
      }
    }
  }
  .list {
    margin-top: 45px;
    .item {
      width: 450px;
      height: 260px;
      background: #fefefe;
      box-shadow: 0px 0px 16px 0px rgba(222, 222, 222, 0.4);
      border-radius: 14px;
      box-sizing: border-box;
      margin-right: 28px;
      margin-bottom: 20px;
      &:nth-child(2n) {
        margin-right: 0;
      }
      .item-top {
        padding: 36px 27px 18px 27px;
        box-sizing: border-box;
        .item-name {
          font-size: 22px;
          font-weight: bold;
          color: #444444;
          margin-bottom: 12px;
        }
        .item-time {
          font-size: 14px;
          color: #999999;
          margin-bottom: 8px;
        }
        .teacher {
          width: 150px;
          height: 24px;
          background: #f2f0ff;
          border-radius: 4px;
          font-size: 16px;
          color: #5f2eff;
        }
      }

      .item-bottom {
        border-top: 1px solid #eeeeee;
        padding: 19px 26px;
        box-sizing: border-box;
        .pic {
          font-size: 29px;
          color: #f53444;
          span {
            font-size: 19px;
            color: #f53444;
          }
        }
        .pic-buy {
          font-size: 19px;
          color: #f53444;
          display: none;
        }
        .detail-btn {
          width: 136px;
          height: 38px;
          border-radius: 18px;
          font-size: 16px;
          color: #ffffff;
          margin-left: auto;
        }
      }
    }
  }
  .live-list {
    .live-item {
      height: 130px;
      margin-top: 40px;
      .order {
        width: 16px;
        height: 16px;
        position: relative;
        margin-left: 16px;
        img {
          position: relative;
        }
        &::before {
          content: "";
          display: block;
          height: 170px;
          width: 2px;
          background: #f4f0ff;
          position: absolute;
          top: 8px;
          left: 7px;
        }
      }

      .time {
        .time-t {
          font-size: 20px;
          color: #333333;
        }
        .time-c {
          font-size: 20px;
          color: #999999;
          margin: 10px 0;
        }
        .time-b {
          width: 80px;
          height: 22px;
          border: 1px solid #5f2eff;
          border-radius: 11px;
          font-size: 12px;
          color: #5f2eff;
        }
      }
      .detail {
        width: 752px;
        height: 130px;
        background: #ffffff;
        box-shadow: 0px 2px 8px 0px rgba(228, 228, 228, 0.7);
        border-radius: 4px;
        position: relative;
        padding: 24px 30px 0 56px;
        box-sizing: border-box;
        margin-left: auto;
        .detail-img {
          width: 68px;
          height: 66px;
          border-radius: 50%;
          background-color: skyblue;
          position: absolute;
          left: -34px;
          top: 33px;
        }
        .detail-name {
          font-size: 20px;
          color: #333333;
          img {
            width: 45px;
            height: 24px;
          }
        }
        .detail-bottom {
          margin-top: 20px;
          .teacher {
            font-size: 16px;
            color: #888888;
            margin-right: 41px;
          }
          .status {
            font-size: 16px;
            color: #5f2eff;
          }
        }
        .detail-pic {
          font-size: 30px;
          font-weight: bold;
          color: #f53444;
        }
        .detail-btn {
          font-size: 16px;
          color: #f53444;
          width: 138px;
          height: 42px;
          border: 1px solid #f53444;
          border-radius: 21px;

          img {
            width: 18px;
            height: 18px;
            margin-right: 10px;
          }
        }
      }
    }
    :last-child {
      .order::before {
        display: none;
      }
    }
  }
}
</style>
